<!--<template>-->
<!--&lt;!&ndash;  <Button :label="label" icon="ti-pencil-alt" class="p-button-warning p-button-raised action-button"/>&ndash;&gt;-->
<!--  <Button :label="label" icon="ti-menu" class="p-button-warning p-button-raised action-button"/>-->
<!--&lt;!&ndash;  <Button :label="label" icon="pi pi-bars" class="p-button-warning p-button-raised action-button"/>&ndash;&gt;-->
<!--</template>-->

<template>
<!--  <Spinner v-if="showSpinner" isSmall :spinnerStyle="'width: 28px; height: 28px'"></Spinner>-->
<!--  <div v-else-->
  <div @click="onRequestActionsBtnClick($event)"
       class="request-actions-wrapper">
<!--    <Button icon="ti-view-list" class="p-button-warning p-button-raised action-button"/>-->
<!--    <Button icon="ti-menu" class="p-button-warning p-button-raised action-button"/>-->
<!--    <Button icon="ti-align-justify" class="p-button-warning p-button-raised action-button"/>-->
<!--    <Button icon="ti-align-justify" class="p-button-warning p-button-raised action-button"/>-->
    <Button icon="pi pi-bars" class="p-button-secondary p-button-raised action-button"/>
<!--    <Button class="p-button-warning p-button-raised action-button">-->
<!--      <div class="p-d-flex p-flex-column" style="position: relative">-->
<!--        <i class="ti-minus" style="position: relative; top: -6px"></i>-->
<!--        <i class="ti-minus" style="position: relative; top: -12px"></i>-->
<!--        <i class="ti-minus" style="position: relative; top: -18px"></i>-->
<!--      </div>-->
<!--    </Button>-->
<!--    <Button class="p-button-warning p-button-raised action-button"><i class="ti-menu" style="font-size: 0.8rem; width: auto;"></i></Button>-->
    <RequestActionsMenu :showSpinner="showSpinner"
                        @leaf-click="onLeafClick"
                        ref="requestActionsMenu"
                        :model="actions"/>
  </div>
</template>

<script>
import RequestActionsMenu from "@/pages/orders/components/RequestActionsMenu";
import httpClient from "@/services/http.services";
import RequestActions from "@/translations/RequestActions";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ formatMixins, showErrorsMixins ],
  components: { RequestActionsMenu },
  name: 'RequestActionsButton',
  emits: ['leaf-click', 'actualize-request-data'],
  props: {
    position: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      serverPositionData: {},
      actions: [],
      showSpinner: false,
      RequestActions
    }
  },
  methods: {
    // onRequestActionsBtnClick2(){
    //   console.log(this.position)
    // },
    onLeafClick(actionType) {
      // if (this.position) {
        this.$emit('leaf-click', {actionType, orderPosition: this.position });
      // }
    },
    onRequestActionsBtnClick(event) {
      // if (this.isDisabled ||
      //     (position.status === 0 && !userIsAdmin) ||
      //     (this.orderIsArchived && !userIsAdmin)) {
      //   event.preventDefault()
      //   return false
      // }
      this.$refs.requestActionsMenu.show(event);
      this.getServerPositionData()
    },
    async getServerPositionData() {
      // console.log('getPositionData')
      this.actions = []
      this.serverPositionData = {}
      if (!this.position) return
      this.showSpinner = true
      try {
        const { status, data } = await httpClient({
          url: 'order-position/get-request-data',
          method: 'post',
          data: {
            id: this.position.id,
            positionHistoryLength: this.position.history?.length,
            positionRequestStateHistoryLength: this.position.request_state_history?.length,
          }
        })
        console.log(data)
        if (status === 200 && data?.success) {
          this.$emit('actualize-request-data', {serverPositionData : data, orderPosition: this.position});
          const state = data.state
          // const maxQtyToReturnWithChangingQty = data.maxQtyToReturnWithChangingQty
          // const maxQtyToReturnWithoutChangingQty = data.maxQtyToReturnWithoutChangingQty
          const maxQtyToReturn = data.maxQtyToReturn
          const maxQtyToDecrease = data.maxQtyToDecrease

          // console.log(this.position.changedMultipleState)
          // if (this.position.state >= 4 && this.position.originalState >= 4) {
            const addAction = RequestActions.find(a => a.actionType === 1)
            if (addAction) {
              this.actions.push(addAction)
            }

            if (maxQtyToDecrease > 0) {
              const decreaseAction = RequestActions.find(a => a.actionType === 2)
              if (decreaseAction) {
                this.actions.push(decreaseAction)
              }
            }

          // }

          if (maxQtyToReturn && state >= 4) { // Reduce action
            const returnAction = RequestActions.find(a => a.actionType === 3)
            if (returnAction) {
              this.actions.push(returnAction)
            }
            // const cancelReturnAction = RequestActions.find(a => a.actionType === 5)
            // if (cancelReturnAction) {
            //   this.actions.push(cancelReturnAction)
            // }
          }

          if (data.reducedQty) {
            const cancelReturnAction = RequestActions.find(a => a.actionType === 5)
            if (cancelReturnAction) {
              this.actions.push(cancelReturnAction)
            }
          }

          if (this.position.status === 0) { //Restore action
            const restoreAction = RequestActions.find(a => a.actionType === 6)
            if (restoreAction) {
              this.actions.push(restoreAction)
            }
          }

          if (this.position.status && this.position.originalState < 4 && maxQtyToDecrease >= this.position.qty) { //Delete action
            const deleteAction = RequestActions.find(a => a.actionType === 7)
            if (deleteAction) {
              this.actions.push(deleteAction)
            }
          }
          // console.log(this.position.id, data)
        } else if (data?.error) {
          console.log(data.error)
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          this.showUnknownError()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.showSpinner = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.action-button {
  margin: 0 3px;
}
.request-actions-wrapper {
  display: block;
  //padding: 6px;
  cursor: pointer;
  //border-radius: 2px;
  //min-width: 23px;
  &:hover {
    opacity: 0.7;
  }
}
</style>