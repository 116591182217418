<template>
  <div v-if="filePath">
<!--    <a @click.prevent="getPdf(filePath)" href="#" class="p-link pdf-img-link">-->
<!--      <img src="assets/images/pdf_md_icon.png"/>-->
<!--      &lt;!&ndash;                      <div>{{ orderData.sales_invoice.number }}</div>&ndash;&gt;-->
<!--    </a>-->
    <a @contextmenu.prevent @click.prevent="getPdf(filePath)" href="#" draggable="false" class="p-link table-link-icon p-d-flex p-ai-center">
      <img src="/assets/images/pdf_sm_icon.gif" draggable="false" alt="pdf_sm" />
    </a>
  </div>
</template>

<script>
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
// import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins ],
  name: 'GetPdfButton',
  props: {
    filePath: String
  },
  methods: {
    async getPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`order/get-task-order-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.showFileNotFoundError()
      }
      // this.isLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.pdf-img-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  //display: flex;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 50px;
  }
}

</style>