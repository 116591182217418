<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ item.transactionType === 1 ?  $t('Increase quantity') : $t('Decrease quantity')}} </div>
    </template>
    <!--    <Spinner v-if="modalDataIsLoading"/>-->
    <Spinner v-if="dataIsSending" :style="'height: 200px'"/>
    <div v-else class="p-formgrid p-grid p-pt-3">
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">{{ item }}</div>-->
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-show="item.code">
        <div class="p-float-label">
          <InputText id="change-request-qty-modal-code" disabled :modelValue="item.code" autocomplete="off"/>
          <label for="change-request-qty-modal-code">{{ $t('Code') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="change-request-qty-modal-name" disabled :modelValue="item.name" autocomplete="off"/>
          <label for="change-request-qty-modal-name">{{ $t('Name') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6" :class="{'p-mb-5' : $store.state.mobileLayout}">
        <div class="p-float-label">
          <!--          <div class="p-inputgroup">-->
          <!--            <span class="p-inputgroup-addon">-->
          <!--              <i :class="item.transactionType === 1 ? 'ti ti-plus' : 'ti ti-minus'"></i>-->
          <!--            </span>-->
<!--          :min="0"-->
<!--          :max="item.transactionType === 1 ? 99999 - item.qty : item.qty"-->
          <InputNumber id="change-request-qty-modal-qty"
                       :inputClass="{'p-invalid' : submitted && (!qty || (maxQtyToDecrease && qty > maxQtyToDecrease))}"
                       :locale="computedNumberInputLocale"
                       @input="qtyOnInput"
                       :min="0"
                       :max="item.transactionType === 1 ? 99999 - item.qty : maxQtyToDecrease"
                       v-model="qty"
                       showButtons
                       autocomplete="off"/>
<!--          <label for="change-request-qty-modal-qty">{{ item.transactionType === 1 ? 'Add qty' : 'Decrease qty'}}<span class="warning-color">*</span></label>-->
          <label for="change-request-qty-modal-qty">{{ $t('Qty') }}<span class="warning-color">*</span></label>
          <!--          </div>-->
        </div>
        <small class="p-invalid" v-if="submitted && !qty">{{ $t('Required field') }}</small>
<!--        <small class="p-invalid" v-else-if="submitted && qty && maxQty">Max qty: {{ maxQty }}</small>-->
        <div>
          <small v-if="item.transactionType === 2" :class="{'p-invalid': submitted && (maxQtyToDecrease && qty > maxQtyToDecrease)}">Max qty: {{ maxQtyToDecrease }}</small>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6">
        <div class="p-float-label">
          <InputNumber id="change-request-qty-modal-new-qty"
                       :locale="computedNumberInputLocale"
                       disabled
                       :modelValue="computedNewQty"
                       autocomplete="off"/>
          <label for="change-request-qty-modal-new-qty">New qty</label>
        </div>
      </div>
<!--      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <table>-->
<!--          <tbody style="text-align: left">-->
<!--            <tr>-->
<!--              <th>Code:</th>-->
<!--              <td>{{ item.code }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Name:</th>-->
<!--              <td>{{ item.name }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>New qty:</th>-->
<!--              <td>{{ item.transactionType === 1 ? item.qty + qty : item.qty - qty }}</td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--&lt;!&ndash;        <div>Code: {{ item.code }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div>Name: {{ item.name }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div>New qty: {{ item.transactionType === 1 ? item.qty + qty : item.qty - qty }}</div>&ndash;&gt;-->
<!--      </div>-->
    </div>
    <template #footer>
      <!--      <Button :label="$t('Cancel')" icon="pi pi-times" :disabled="modalDataIsLoading" class="p-button-text" @click="close"/>-->
      <!--      <Button :label="$t('Save')" :disabled="!positionData?.qty || modalDataIsLoading" icon="pi pi-check" class="p-button-text" @click="saveItem" />-->
      <Button :label="$t('Cancel')" icon="pi pi-times" :disabled="dataIsSending" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="dataIsSending" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from "@/services/http.services";
import formatMixins from "@/mixins/formatMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import settings from "@/settings";

export default {
  mixins: [ formatMixins, showErrorsMixins ],
  emits: ['close', 'actualize-request-data'],
  name: "ChangeRequestQtyModal",
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      submitted: false,
      dataIsSending: false,
      // positionData: {},
      qty: 0,
      // maxQty: 1,
      maxQtyToDecrease: null,
      newQty: 0,
    }
  },
  watch: {
    item(value) {
      // this.qty = value.transactionType === 1  ? 1 : -1
      // if (value.transactionType === 1 && value.qty === 1) {
      //   this.qty = 1
      // } else if (value.transactionType === 2 && value.maxQtyToDecrease === 1) {
      //   this.qty = 1
      // } else {
      //   this.qty = null
      // }
      this.maxQtyToDecrease = value.transactionType === 2 ? value.maxQtyToDecrease : null
      // this.newQty = this.qty + value.qty
      // this.newQty = value.qty
      // this.calculateNewQty(1)
      // this.maxQty = -value.qty
      // console.log(this.maxQty)
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        // this.qty = this.item.transactionType === 1  ? 1 : -1
        this.dataIsSending = false
        this.qty = 0
        this.newQty = 0
        // this.maxQty = null
        this.maxQtyToDecrease = null
        // this.maxQty = null
      }
    },
  },
  methods: {
    qtyOnInput(event) {
      let value = event.value
      // console.log(value)
      // if (value === '-') {
      //   value = 0
      //   // return
      // }
      if (value === '-' || !value || +value < 0) {
        value = 0
      }

      if (this.item.transactionType === 2 && (value > this.maxQtyToDecrease)) {
        value = this.maxQtyToDecrease
      } else if (this.item.transactionType === 1 && (this.item.qty + value > 99999)) {
        value = 99999 - this.item.qty
        // console.log(value)
      }

      this.qty = +value
    },
    // calculateNewQty(value) {
    //   if (!value) return
    //   if (this.item.transactionType === 1) {
    //     this.newQty = this.item.qty + value
    //   } else if (this.item.transactionType === 2) {
    //     this.newQty = this.item.qty - value
    //   }
    // },
    async saveItem() {
      this.submitted = true
      if (!this.qty ||
          (this.maxQtyToDecrease && this.qty > this.maxQtyToDecrease))  {
        return false
      }
      this.dataIsSending = true

      const obj = {
        order_id: this.item.order_id,
        order_position_id: this.item.id,
        oldQty: this.item.qty,
        // qty: this.item.transactionType === 1 ? this.qty : -this.qty,
        increasedQty: this.item.transactionType === 1 ? this.qty : null,
        decreasedQty: this.item.transactionType === 2 ? this.qty : null,
        positionHistoryLength: this.item.history?.length,
        positionRequestStateHistoryLength: this.item.request_state_history?.length,
      }

      try {
        const { status, data } = await httpClient.post(`order-position/change-request-qty`, obj)
        // console.log(data)
        if (status === 201 && data && (data.success || data.max_qty_error)) {
          let sendSocket = false
          let updateItem = true

          if (data.success) {
            sendSocket = true
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
            this.close()
          } else if (data.max_qty_error) {
            console.log('data.max_qty_erro')
            this.maxQtyToDecrease = data.maxQtyToDecrease || +data.maxQtyToDecrease === 0 ? +data.maxQtyToDecrease : 0
            if (this.maxQtyToDecrease === 0) {
              updateItem = false
              this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
              this.close()
            } else {
              this.$toast.add({severity:'error', detail: 'Maximum qty to decrease: ' + data.maxQtyToDecrease, life: settings.toastLife})
            }
          }
          this.$emit('actualize-request-data', {serverPositionData : data, orderPosition: this.item, action: 'change-qty', sendSocket, updateItem});
        } else if (data?.error) {
          console.log(data.error)
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          this.showUnknownError()
        }
      // else if (data.max_qty_error) {
      //     this.maxQtyToDecrease = data.maxQtyToDecrease
      //     this.$emit('update-qty', data)
      //     if (+data.maxQtyToDecrease === 0) {
      //       this.close()
      //     } else {
      //       this.$toast.add({severity:'error', detail: 'Maximum qty to decrease: ' + data.maxQtyToDecrease, life: settings.toastLife});
      //     }
      //   }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedNewQty() {
      if (!this.item) return 0
      const newQty = this.item.transactionType === 1 ? this.item.qty + this.qty : this.item.qty - this.qty
      return newQty
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '85%', maxWidth: '320px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '320px'}
      } else {
        return {width: '320px'}
      }
    }
  },
}
</script>

<style scoped>

</style>